import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  getTotalEKLAmount,
  getTotalSwapFee,
  getTotalVEKLAmount,
  getUntransmittedEKL,
  getVotePortion,
  getEKLPrice,
  getTVL,
  get24Volume,
} from "../../../utils/contractCall";
import { thousandSeparator } from "../../../utils/util";
// import { getTotalEKLAmount } from "../../../utils/contractCall";
import MainStatButton from "../../MainStatButton/MainStatButton";
import "./MainStatPage.scss";

const MainStatPage = () => {
  const [totalEklLocked, setTotalEklLocked] = useState(0);
  const [totalVEKL, setTotalVEKL] = useState(0);
  const [swapFee, setSwapFee] = useState(0);
  const [untransmittedEKL, setUntransimittedEKL] = useState(0);
  const [votePortion, setVotePortion] = useState(0);
  const [eklPrice, setEklPrice] = useState(0);
  const [tvl, setTVL] = useState(0);
  const [volume, setVolume] = useState(0);
  const navigate = useNavigate();
  const init = async () => {
    setTotalEklLocked(await getTotalEKLAmount());
    setTotalVEKL(await getTotalVEKLAmount());
    setSwapFee(await getTotalSwapFee());
    setUntransimittedEKL(await getUntransmittedEKL());
    setVotePortion(await getVotePortion());
    setEklPrice(await getEKLPrice());
    setTVL(await getTVL());
    setVolume(await get24Volume());
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <div id="main-stat-page">
      <h1 className="title">Eklipse Main Stats</h1>
      <div className="stat-button-container">
        <MainStatButton
          title={"EKLPrice"}
          value={"$ " + eklPrice}
          onClick={() =>
            window.open(
              "https://swapscanner.io/ko/swap?from=0xcee8faf64bb97a73bb51e115aa89c17ffa8dd167&to=0x807c4e063eb0ac21e8eef7623a6ed50a8ede58ca"
            )
          }
        />
        <MainStatButton
          title={"TVL"}
          value={"$ " + thousandSeparator(tvl)}
          // onClick={() => navigate("tvl")}
        />
        <MainStatButton
          title={"24 Volume"}
          value={"$ " + thousandSeparator(volume)}
        />
        <MainStatButton
          title={"Swap Fee"}
          value={"$ " + thousandSeparator(swapFee)}
          onClick={() => navigate("swap")}
        />
        <MainStatButton
          title={"Voting Participant"}
          value={votePortion + "%"}
          onClick={() => navigate("gauge")}
        />
        <MainStatButton
          title={"Highest APR"}
          value={"EKL-KLAY LP"}
          onClick={() => {
            // window.open("https://app.eklipse.finance/farm/EKL_KLAY?isEKL=true");
            window.open(
              "https://klayswap.com/exchange/pool/detail/0x5db231ac93faaad876155dc0853bb11a2f4b0fb2"
            );
          }}
        />
        <MainStatButton
          title={"Untransmitted EKL"}
          value={thousandSeparator(untransmittedEKL)}
          onClick={() => navigate("gauge")}
        />
        <MainStatButton
          title={"Total EKL Locked"}
          value={thousandSeparator(totalEklLocked)}
          // onClick={() => navigate("vEKL")}
        />
        <MainStatButton
          title={"Total vEKL"}
          value={thousandSeparator(totalVEKL)}
          // onClick={() => navigate("vEKL")}
        />
      </div>
    </div>
  );
};
export default MainStatPage;
