import {
  getThreeMoonBUSDUntransmittedEKL,
  getThreeMoonBUSDVotePortion,
  getThreeMoonKASHUntransmittedEKL,
  getThreeMoonKASHVotePortion,
  getThreeMoonKSDUntransmittedEKL,
  getThreeMoonKSDVotePortion,
  getThreeMoonPUSDUntransmittedEKL,
  getThreeMoonPUSDVotePortion,
  getThreeMoonUntransmittedEKL,
  getThreeMoonUSDKUntransmittedEKL,
  getThreeMoonUSDKVotePortion,
  getTriCryptoVotePortion,
  getThreeMoonVotePortion,
  getTriCryptoUntransmittedEKL,
  getThreeMoonUSDFUntransmittedEKL,
  getThreeMoonUSDFVotePortion,
} from "../utils/contractCall";

export enum gaugeType {
  ThreeMoon = "ThreeMoon",
  BUSD = "BUSD",
  KSD = "KSD",
  KASH = "KASH",
  PUSD = "PUSD",
  USDK = "USDK",
  USDF = "USDF",
  TRICRYPTO = "TRICRYPTO",
}

export class GaugeInfoService {
  gaugeType?: gaugeType;
  constructor(gaugeType: gaugeType) {
    this.gaugeType = gaugeType;
  }

  async getUntransmittedEKL() {
    if (this.gaugeType === gaugeType.ThreeMoon) {
      return await getThreeMoonUntransmittedEKL();
    } else if (this.gaugeType === gaugeType.BUSD) {
      return await getThreeMoonBUSDUntransmittedEKL();
    } else if (this.gaugeType === gaugeType.KSD) {
      return await getThreeMoonKSDUntransmittedEKL();
    } else if (this.gaugeType === gaugeType.KASH) {
      return await getThreeMoonKASHUntransmittedEKL();
    } else if (this.gaugeType === gaugeType.PUSD) {
      return await getThreeMoonPUSDUntransmittedEKL();
    } else if (this.gaugeType === gaugeType.USDK) {
      return await getThreeMoonUSDKUntransmittedEKL();
    } else if (this.gaugeType === gaugeType.USDF) {
      return await getThreeMoonUSDFUntransmittedEKL();
    } else if (this.gaugeType === gaugeType.TRICRYPTO) {
      return await getTriCryptoUntransmittedEKL();
    }
    return 0;
  }

  getGaugeName() {
    if (this.gaugeType === gaugeType.ThreeMoon) {
      return "3Moon";
    } else if (this.gaugeType === gaugeType.BUSD) {
      return "3Moon-BUSD";
    } else if (this.gaugeType === gaugeType.KSD) {
      return "3Moon-KSD";
    } else if (this.gaugeType === gaugeType.KASH) {
      return "3Moon-KASH";
    } else if (this.gaugeType === gaugeType.PUSD) {
      return "3Moon-PUSD";
    } else if (this.gaugeType === gaugeType.USDK) {
      return "3Moon-USDK";
    } else if (this.gaugeType === gaugeType.USDF) {
      return "3Moon-USDF";
    } else if (this.gaugeType === gaugeType.TRICRYPTO) {
      return "ekl-tri-crypto";
    }
    return "";
  }

  async getVotingResult() {
    if (this.gaugeType === gaugeType.ThreeMoon) {
      return await getThreeMoonVotePortion();
    } else if (this.gaugeType === gaugeType.BUSD) {
      return await getThreeMoonBUSDVotePortion();
    } else if (this.gaugeType === gaugeType.KSD) {
      return await getThreeMoonKSDVotePortion();
    } else if (this.gaugeType === gaugeType.KASH) {
      return await getThreeMoonKASHVotePortion();
    } else if (this.gaugeType === gaugeType.PUSD) {
      return await getThreeMoonPUSDVotePortion();
    } else if (this.gaugeType === gaugeType.USDK) {
      return await getThreeMoonUSDKVotePortion();
    } else if (this.gaugeType === gaugeType.USDF) {
      return await getThreeMoonUSDFVotePortion();
    } else if (this.gaugeType === gaugeType.TRICRYPTO) {
      return await getTriCryptoVotePortion();
    }
    return [0, 1];
  }

  getTokenList() {
    if (this.gaugeType === gaugeType.ThreeMoon) {
      return ["kDAI", "kUSDT", "kUSDC"];
    } else if (this.gaugeType === gaugeType.BUSD) {
      return ["kDAI", "kUSDT", "kUSDC", "kBUSD"];
    } else if (this.gaugeType === gaugeType.KSD) {
      return ["kDAI", "kUSDT", "kUSDC", "KSD"];
    } else if (this.gaugeType === gaugeType.KASH) {
      return ["kDAI", "kUSDT", "kUSDC", "KASH"];
    } else if (this.gaugeType === gaugeType.PUSD) {
      return ["kDAI", "kUSDT", "kUSDC", "PUSD"];
    } else if (this.gaugeType === gaugeType.USDK) {
      return ["kDAI", "kUSDT", "kUSDC", "USDK"];
    } else if (this.gaugeType === gaugeType.USDF) {
      return ["kDAI", "kUSDT", "kUSDC", "USDF"];
    } else if (this.gaugeType === gaugeType.TRICRYPTO) {
      return ["kUSDT", "KLAY", "XRP"];
    }

    return [];
  }
}
