/// token
export const EKLTokenAddress = "0x807c4e063eb0ac21e8eef7623a6ed50a8ede58ca";
export const PostEKLTokenAddress = "0x0e23BeE35717987E71Fa8445E4Dd750aD718BA8a";
export const threeMoonLPTokenAddress =
  "0xd83b9dFa49D6C6d2A69554576e712E45A8A13E49";
export const threeMoonBUSDLPTokenAddress =
  "0x9EE1cE4ccF4c0379e675d9A326d21aacDbB55F72";
export const threeMoonKSDLPTokenAddress =
  "0xa4D48E724c7F2267918B5155094FB01437980604";
export const threeMoonKASHLPTokenAddress =
  "0x9D5b7671CdDbA4bb82E99fBcedf60C4D001Fe2EF";
export const threeMoonPUSDLPTokenAddress =
  "0x63C7d72963ED6e0C255835B606BEF55EBEB1b5f8";
export const threeMoonUSDKLPTokenAddress =
  "0xc4ACf4ddd4838E9A727cCDb75ae62Af1706a7173";
export const triCryptoLPTokenAddress =
  "0x95E4699a4Ac981d0afab6803429A1ee2f7Ec074d";
export const pusdKornLPTokenAddress =
  "0xA4751F5FC9DBB95904F3B809293f62Ed5cbA9541";
export const eklUSDTLPTokenAddress =
  "0x219ee5d76593f5bd639125b6411a17d309e3ad31";
export const eklKlayLPTokenAddress =
  "0x5db231ac93faaad876155dc0853bb11a2f4b0fb2";

/// contract
export const threeMoonAddress = "0x4F5d9F3b17988aA047e6F1Bc511fEc0BF25691f4";
export const threeMoonBUSDAddress =
  "0xddA06aaB425a1A390c131F790A56AB3380e3B7EC";
export const threeMoonKSDAddress = "0x7f352a4332fAD433D381d700118f8C9b0A1E1abb";
export const threeMoonKASHAddress =
  "0xB1b782f2D30505e9984e37e00C6494437d94c223";
export const threeMoonPUSDAddress =
  "0xe59234EeDC854b3b37D48EFd8a529069C3990F83";
export const threeMoonUSDKAddress =
  "0x75Dc33f8247245E8E08852E68E7f275E2a41fD40";
export const threeMoonUSDFAddress =
  "0x5176Fc5B3C2458da1Ce7f757B8E816AB2e6C5878";
export const triCryptoAddress = "0xA7e4dA4C3aa44C7350E7ec56E973bCee616086CD";
export const pusdKornAddress = "0x67004AF69EDf25ceFc0D4C50e88F7dc47b642e3B";

export const threeMoonGaugeAddress =
  "0xCeAAF9f6C8147B2A7Cd8bD4E9fA8955b430Eb423";
export const threeMoonBUSDGaugeAddress =
  "0x71143d3f2491c3956f3b4b8A329472af31963c51";
export const threeMoonKSDGaugeAddress =
  "0x1daD4757f1DE9e090724A39b765f154760E3f62A";
export const threeMoonKASHGaugeAddress =
  "0x789fc46319e51D956b003f1F552E04F922dCB035";
export const threeMoonPUSDGaugeAddress =
  "0x0B9932F158509671f7A70b0FB45e58BCdC6fe083";
export const threeMoonUSDKGaugeAddress =
  "0x85338F94844673b636e632eb21575E1e21864cbc";
export const threeMoonUSDFGaugeAddress =
  "0x8df0894A25cB339d402a068F0e5137194b3CA1C7";
export const triCryptoGaugeAddress =
  "0x2726e8602b217213aFc07F75e0487f80B36F017F";

export const lpFarmAddress = "0x625ae9043e8730c4a1e30b36838502fb90e1d3c2";

export const voteContractAddress = "0x18428b7826C2588207e39b742c15642B8D9755B4";
export const lockContractAddress = "0xD067C3b871ee9E07BA4205A8F96c182baBBA6c58";

export const klaySwapAddress = "0x219ee5d76593f5bd639125b6411a17d309e3ad31";

export const firebaseEnvironment = {
  projectId: "eklipse-d0a0c",
  appId: "1:689935262528:web:ef46c8b5af309fe07b50a5",
  storageBucket: "eklipse-d0a0c.appspot.com",
  locationId: "asia-northeast3",
  apiKey: "AIzaSyDegZoNwe5-56ihxBfT8IiaBpisqWiMNYk",
  authDomain: "eklipse-d0a0c.firebaseapp.com",
  messagingSenderId: "689935262528",
  measurementId: "G-23TJ5HGPQW",
};

export const START_BLOCK = 82570552;
export const EVENTS_PER_QUERY = 1000000;
