import "./GaugeInfoPage.scss";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { useEffect, useState } from "react";
import { PieChartData } from "../../../interface";
import {
  getAppliedPortionData,
  getPortionData,
} from "../../../utils/contractCall";
import GaugeInfo from "../../GaugeInfo/GaugeInfo";
import { gaugeType } from "../../../service/GaugeInfoService";

const COLORS = [
  "rgb(48,119,201)",
  "rgb(204,163,57)",
  "rgb(254,240,81)",
  "rgb(165,227,165)",
  "rgb(250,221,120)",
  "rgb(136,176,98)",
  "rgb(63,197,89)",
  "rgb(193,15,29)",
];

const GaugeInfoPage = () => {
  const [currentAppliedData, setCurrentAppliedData] = useState<PieChartData[]>(
    []
  );
  const [currentVotingData, setCurrentVotingData] = useState<PieChartData[]>(
    []
  );

  useEffect(() => {
    init();
  }, []);

  const renderCustomChartLabel = ({
    payload,
    x,
    y,
    width,
    height,
    value,
  }: any) => {
    return payload.name + ": " + payload.value + "%";
  };

  const init = async () => {
    const portionData = await getPortionData();
    setCurrentVotingData(portionData);
    setCurrentAppliedData(await getAppliedPortionData());
  };

  return (
    <div id="gauge-info-page">
      <h1>Gauge Info</h1>
      <span className="chart-title">Next week Gauge Allocation</span>
      <PieChart
        width={600}
        height={500}
        className="current-voting-chart"
        style={{ width: "auto", height: "auto" }}
      >
        <Pie
          isAnimationActive={false}
          data={currentVotingData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={150}
          fill="#638cf6"
          label={renderCustomChartLabel}
          labelLine
        >
          {currentVotingData.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={COLORS[index]} />;
          })}
        </Pie>
        <Tooltip />
      </PieChart>
      <span className="chart-title">Current week Gauge Allocation</span>
      <PieChart
        width={600}
        height={500}
        className="current-voting-chart"
        style={{ width: "auto", height: "auto" }}
      >
        <Pie
          isAnimationActive={false}
          data={currentAppliedData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={150}
          fill="#638cf6"
          label={renderCustomChartLabel}
          labelLine
        >
          {currentVotingData.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={COLORS[index]} />;
          })}
        </Pie>
        <Tooltip />
      </PieChart>
      <div className="gauge-info-container">
        <GaugeInfo gaugeType={gaugeType.ThreeMoon} />
        <GaugeInfo gaugeType={gaugeType.BUSD} />
        <GaugeInfo gaugeType={gaugeType.KSD} />
        <GaugeInfo gaugeType={gaugeType.KASH} />
        <GaugeInfo gaugeType={gaugeType.PUSD} />
        <GaugeInfo gaugeType={gaugeType.USDK} />
        <GaugeInfo gaugeType={gaugeType.USDF} />
        <GaugeInfo gaugeType={gaugeType.TRICRYPTO} />
      </div>
    </div>
  );
};

export default GaugeInfoPage;
