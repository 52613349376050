import { swapType } from "../../../service/SwapInfoService";
import SwapInfo from "../../swapInfo/SwapInfo";
import "./SwapInfoPage.scss";

const SwapInfoPage = () => {
  return (
    <div id="swap-info-page">
      <h1>Swap Info</h1>
      <div className="swap-info-container">
        <SwapInfo swapType={swapType.ThreeMoon} />
        <SwapInfo swapType={swapType.BUSD} />
        <SwapInfo swapType={swapType.KSD} />
        <SwapInfo swapType={swapType.KASH} />
        <SwapInfo swapType={swapType.PUSD} />
        <SwapInfo swapType={swapType.USDK} />
        <SwapInfo swapType={swapType.USDF} />
        <SwapInfo swapType={swapType.TRICRYPTO} />
        <SwapInfo swapType={swapType.PUSD_KORN} />
      </div>
    </div>
  );
};

export default SwapInfoPage;
