import { useLocation, useNavigate } from "react-router";
import "./GlobalNavigationBar.scss";

const GlobalNavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const onClickLogo = () => {
    window.open("https://app.eklipse.finance/");
  };
  const onClickGoHome = () => {
    navigate("/");
  };
  return (
    <div id="global-navigation-bar">
      <div className="logo" onClick={onClickLogo}></div>
      <div className="router">
        <div className="router-button" onClick={() => navigate("/swap")}>
          swap
        </div>
        <div className="router-button" onClick={() => navigate("/gauge")}>
          farm(gauge)
        </div>
        {/* <div
          className="router-button"
          // onClick={() => navigate("/vEKL")}
        >
          vEKL
        </div>
        <div className="router-button" onClick={() => navigate("/tvl")}>
          TVL
        </div> */}
      </div>
      {location.pathname !== "/" ? (
        <div className="go-home" onClick={onClickGoHome}>
          Back To Main
        </div>
      ) : (
        <div className="empty" />
      )}
    </div>
  );
};

export default GlobalNavigationBar;
