import { useRoutes } from "react-router-dom";
import "./App.css";
import GlobalNavigationBar from "./components/GlobalNavigationBar/GlobalNavigationBar";
import GaugeInfoPage from "./components/pages/gaugeInfoPage/GaugeInfoPage";
import MainStatPage from "./components/pages/mainStatPage/MainStatPage";
import SwapInfoPage from "./components/pages/swapInfoPage/SwapInfoPage";
import TVLInfoPage from "./components/pages/TVLInfoPage/TVLInfoPage";
import VEKLInfoPage from "./components/pages/vEKLInfoPage/VEKLInfoPage";
import StarryNight from "./components/StarryNight";

function App() {
  let routes = useRoutes([
    { path: "/", element: <MainStatPage /> },
    // { path: "/vekl", element: <VEKLInfoPage /> },
    { path: "/swap", element: <SwapInfoPage /> },
    { path: "/gauge", element: <GaugeInfoPage /> },
    // { path: "/tvl", element: <TVLInfoPage /> },
  ]);
  return (
    <div className="App">
      <StarryNight />
      <div>
        <GlobalNavigationBar />
        {routes}
      </div>
    </div>
  );
}

export default App;
