import { useEffect, useState } from "react";
import { SwapInfoService, swapType } from "../../service/SwapInfoService";
import { thousandSeparator } from "../../utils/util";
import "./SwapInfo.scss";

interface Props {
  swapType: swapType;
}

const SwapInfo = ({ swapType }: Props) => {
  const [poolName, setPoolName] = useState("");
  const [swapFee, setSwapFee] = useState<{ token: string; fee: BigInt }[]>([]);
  const init = async () => {
    const swapInfoService = new SwapInfoService(swapType);
    setPoolName(swapInfoService.getSwapName());

    setSwapFee(await swapInfoService.getSwapFee());
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <div className="swap-info">
      <span className="title">{poolName}</span>
      <div className="info-container">
        <div className="info-row">
          <span className="info-label swap-fee">Swap Fee</span>
          <div className="fee-info-container">
            {swapFee.map((feeInfo) => {
              return (
                <div className="fee-info-row" key={feeInfo.token}>
                  {feeInfo.token === "3Moon-LP" ? (
                    <div className="contianer">
                      <div className={"token kDAI combine1"} />
                      <div className={"token kUSDT combine2"} />
                      <div className={"token kUSDC combine3"} />
                    </div>
                  ) : (
                    <div className={"token " + feeInfo.token} />
                  )}
                  <span className="token-name">{feeInfo.token}</span>
                  <span className="fee-value">
                    {feeInfo.fee < BigInt(0)
                      ? "-"
                      : "$" +
                        thousandSeparator(Math.floor(Number(feeInfo.fee)))}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwapInfo;
