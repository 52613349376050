import { Link } from "react-router-dom";
import "./MainStatButton.scss";

interface IProp {
  title: string;
  value: number | string;
  onClick?: () => void;
}

const MainStatButton = (props: IProp) => {
  return (
    <div className="main-stat-button" onClick={props.onClick}>
      <span className="title">{props.title}</span>
      <span className="value">{props.value}</span>
    </div>
  );
};

export default MainStatButton;
