import Caver from "caver-js";
import { klayswapABI } from "../abi/abi_klayswap";
import { eklipseGaugeABI } from "../abi/eklipse_farm";
import { eklipseLockABI } from "../abi/eklipse_lock";
import { EklipseLp3moonABI } from "../abi/eklipse_lp_3moon";
import { EklipseLp4moonABI } from "../abi/eklipse_lp_4moon";
import { eklipseVoteABI } from "../abi/eklipse_vote";
import { erc20ABI } from "../abi/erc_20";
import { klayswapLPAbi } from "../abi/klayswap";
import { cryptoSwapAbi } from "../abi/eklipse_crypto_lp";
import {
  EKLTokenAddress,
  klaySwapAddress,
  lockContractAddress,
  threeMoonAddress,
  threeMoonBUSDAddress,
  threeMoonBUSDGaugeAddress,
  threeMoonGaugeAddress,
  threeMoonKASHAddress,
  threeMoonKASHGaugeAddress,
  threeMoonKSDAddress,
  threeMoonKSDGaugeAddress,
  threeMoonPUSDAddress,
  threeMoonPUSDGaugeAddress,
  threeMoonUSDKAddress,
  threeMoonUSDKGaugeAddress,
  voteContractAddress,
  eklUSDTLPTokenAddress,
  eklKlayLPTokenAddress,
  lpFarmAddress,
  threeMoonLPTokenAddress,
  threeMoonBUSDLPTokenAddress,
  threeMoonKSDLPTokenAddress,
  threeMoonKASHLPTokenAddress,
  threeMoonPUSDLPTokenAddress,
  threeMoonUSDKLPTokenAddress,
  triCryptoGaugeAddress,
  triCryptoAddress,
  threeMoonUSDFAddress,
  threeMoonUSDFGaugeAddress,
  triCryptoLPTokenAddress,
  pusdKornAddress,
  pusdKornLPTokenAddress,
} from "../const";
import { PieChartData } from "../interface";
import { collection, getDocs } from "firebase/firestore/lite";
import { db } from "./util";
import { cryptoSwapAbi2 } from "../abi/eklipse_crypto_lp2";
declare const klaytn: any;
// const caver = new Caver("https://cypress.eklipse.io:8551/");
const caver = new Caver(klaytn);
const allSwapContract = [
  threeMoonAddress,
  threeMoonBUSDAddress,
  threeMoonKSDAddress,
  threeMoonKASHAddress,
  threeMoonPUSDAddress,
  threeMoonUSDKAddress,
  threeMoonUSDFAddress,
];

const allCryptoPoolAddress = [triCryptoAddress];

const allGaugeContractAddress = [
  threeMoonGaugeAddress,
  threeMoonBUSDGaugeAddress,
  threeMoonKSDGaugeAddress,
  threeMoonKASHGaugeAddress,
  threeMoonPUSDGaugeAddress,
  threeMoonUSDKGaugeAddress,
];

const allLpTokenAddress = [
  threeMoonLPTokenAddress,
  threeMoonBUSDLPTokenAddress,
  threeMoonKSDLPTokenAddress,
  threeMoonKASHLPTokenAddress,
  threeMoonPUSDLPTokenAddress,
  threeMoonUSDKLPTokenAddress,
];

const eklUsdtLpTokenContract = new caver.klay.Contract(
  klayswapLPAbi as any,
  eklUSDTLPTokenAddress
);
const eklKlayLpTokenContract = new caver.klay.Contract(
  klayswapLPAbi as any,
  eklKlayLPTokenAddress
);

const lockContract = new caver.klay.Contract(
  eklipseLockABI as any,
  lockContractAddress
);
const voteContract = new caver.klay.Contract(
  eklipseVoteABI as any,
  voteContractAddress
);
const eklTokenContract = new caver.klay.Contract(
  erc20ABI as any,
  EKLTokenAddress
);
const threeMoonSwapContract = new caver.klay.Contract(
  EklipseLp3moonABI as any,
  threeMoonAddress
);
const threeMoonBUSDSwapContract = new caver.klay.Contract(
  EklipseLp4moonABI as any,
  threeMoonBUSDAddress
);
const threeMoonKSDSwapContract = new caver.klay.Contract(
  EklipseLp4moonABI as any,
  threeMoonKSDAddress
);
const threeMoonKASHSwapContract = new caver.klay.Contract(
  EklipseLp4moonABI as any,
  threeMoonKASHAddress
);
const threeMoonPUSDSwapContract = new caver.klay.Contract(
  EklipseLp4moonABI as any,
  threeMoonPUSDAddress
);
const threeMoonUSDKSwapContract = new caver.klay.Contract(
  EklipseLp4moonABI as any,
  threeMoonUSDKAddress
);
const threeMoonUSDFSwapContract = new caver.klay.Contract(
  EklipseLp4moonABI as any,
  threeMoonUSDFAddress
);
const triCryptoSwapContract = new caver.klay.Contract(
  cryptoSwapAbi as any,
  triCryptoAddress
);
const pusdKornSwapContract = new caver.klay.Contract(
  cryptoSwapAbi2 as any,
  pusdKornAddress
);
const threeMoonGaugeContract = new caver.klay.Contract(
  eklipseGaugeABI as any,
  threeMoonGaugeAddress
);
const threeMoonBUSDGaugeContract = new caver.klay.Contract(
  eklipseGaugeABI as any,
  threeMoonBUSDGaugeAddress
);
const threeMoonKSDGaugeContract = new caver.klay.Contract(
  eklipseGaugeABI as any,
  threeMoonKSDGaugeAddress
);
const threeMoonKASHGaugeContract = new caver.klay.Contract(
  eklipseGaugeABI as any,
  threeMoonKASHGaugeAddress
);
const threeMoonPUSDGaugeContract = new caver.klay.Contract(
  eklipseGaugeABI as any,
  threeMoonPUSDGaugeAddress
);
const threeMoonUSDKGaugeContract = new caver.klay.Contract(
  eklipseGaugeABI as any,
  threeMoonUSDKGaugeAddress
);
const threeMoonUSDFGaugeContract = new caver.klay.Contract(
  eklipseGaugeABI as any,
  threeMoonUSDFGaugeAddress
);
const triCryptoGaugeContract = new caver.klay.Contract(
  eklipseGaugeABI as any,
  triCryptoGaugeAddress
);

const klaySwapContract = new caver.klay.Contract(
  klayswapABI as any,
  klaySwapAddress
);

const START_BLOCK = 82570552;
const EVENTS_PER_QUERY = 1000000;

const getTokenContract = (address: string) => {
  return new caver.klay.Contract(erc20ABI as any, address);
};

export const getTotalEKLAmount = async () => {
  const balance = await eklTokenContract.methods
    .balanceOf(lockContractAddress)
    .call();
  return Math.floor(balance / 10 ** 18);
};

export const getTotalVEKLAmount = async () => {
  const total = await lockContract.methods.totalVekl().call();
  return Math.floor(total / 10 ** 18);
};

export const getThreeMoonFee = async () => {
  const threeMoonFee = await threeMoonSwapContract.methods
    .getAdminBalances()
    .call();
  return threeMoonFee;
};

export const getThreeMoonBUSDFee = async () => {
  const threeMoonBUSDFee = await threeMoonBUSDSwapContract.methods
    .getAdminBalances()
    .call();
  return threeMoonBUSDFee;
};
export const getThreeMoonKSDFee = async () => {
  const threeMoonKSDFee = await threeMoonKSDSwapContract.methods
    .getAdminBalances()
    .call();
  return threeMoonKSDFee;
};
export const getThreeMoonKASHFee = async () => {
  const threeMoonKASHFee = await threeMoonKASHSwapContract.methods
    .getAdminBalances()
    .call();
  return threeMoonKASHFee;
};
export const getThreeMoonPUSDFee = async () => {
  const threeMoonPUSDFee = await threeMoonPUSDSwapContract.methods
    .getAdminBalances()
    .call();
  return threeMoonPUSDFee;
};
export const getThreeMoonUSDKFee = async () => {
  const threeMoonUSDKFee = await threeMoonUSDKSwapContract.methods
    .getAdminBalances()
    .call();
  return threeMoonUSDKFee;
};

export const getThreeMoonUSDFFee = async () => {
  const threeMoonUSDFFee = await threeMoonUSDFSwapContract.methods
    .getAdminBalances()
    .call();
  return threeMoonUSDFFee;
};

export const getTriCryptoFee = async () => {
  const triCryptoFee = await triCryptoSwapContract.methods
    .calcAdminFees()
    .call();
  const totSup = await getTokenContract(triCryptoLPTokenAddress)
    .methods.totalSupply()
    .call();
  const lpAmount = BigInt(totSup * triCryptoFee) / BigInt(10 ** 18);
  const calcUSDT = await triCryptoSwapContract.methods
    .calcWithdrawOneCoin(lpAmount, 0)
    .call();
  return [calcUSDT, -BigInt(10 ** 18), -BigInt(10 ** 18)];
};

export const getPopkornFee = async () => {
  const popkornFee = await pusdKornSwapContract.methods.calcAdminFees().call();
  const totSup = await getTokenContract(pusdKornLPTokenAddress)
    .methods.totalSupply()
    .call();
  const lpAmount = BigInt(totSup * popkornFee) / BigInt(10 ** 18);
  const calcPUSD = await pusdKornSwapContract.methods
    .calcWithdrawOneCoin(lpAmount, 0)
    .call();
  return [calcPUSD, -BigInt(10 ** 18)];
};

export const getTotalSwapFee = async () => {
  const threeMoonFee = await getThreeMoonFee();
  const threeMoonBUSDFee = await getThreeMoonBUSDFee();
  const threeMoonKSDFee = await getThreeMoonKSDFee();
  const threeMoonKASHFee = await getThreeMoonKASHFee();
  const threeMoonPUSDFee = await getThreeMoonPUSDFee();
  const threeMoonUSDKFee = await getThreeMoonUSDKFee();
  const triCryptoFee = await getTriCryptoFee();
  const popkornFee = await getPopkornFee();
  let ans = 0;
  ans +=
    Number(threeMoonFee[0]) +
    Number(threeMoonFee[1]) * 10 ** 12 +
    Number(threeMoonFee[2]) * 10 ** 12;
  ans += Number(threeMoonBUSDFee[0]) + Number(threeMoonBUSDFee[1]);
  ans += Number(threeMoonKSDFee[0]) + Number(threeMoonKSDFee[1]);
  ans += Number(threeMoonKASHFee[0]) + Number(threeMoonKASHFee[1]);
  ans += Number(threeMoonPUSDFee[0]) + Number(threeMoonPUSDFee[1]);
  ans += Number(threeMoonUSDKFee[0]) + Number(threeMoonUSDKFee[1]);
  ans += Number(triCryptoFee[0]);
  ans += Number(popkornFee[0]);
  return Math.floor(ans / 10 ** 18);
};

export const getThreeMoonUntransmittedEKL = async () => {
  const threeMoonUntransmittedEKL = await threeMoonGaugeContract.methods
    .untransmittedEkl()
    .call();
  return threeMoonUntransmittedEKL;
};

export const getThreeMoonBUSDUntransmittedEKL = async () => {
  const threeMoonBUSDUntransmittedEKL = await threeMoonBUSDGaugeContract.methods
    .untransmittedEkl()
    .call();
  return threeMoonBUSDUntransmittedEKL;
};

export const getThreeMoonKSDUntransmittedEKL = async () => {
  const threeMoonKSDUntransmittedEKL = await threeMoonKSDGaugeContract.methods
    .untransmittedEkl()
    .call();
  return threeMoonKSDUntransmittedEKL;
};

export const getThreeMoonKASHUntransmittedEKL = async () => {
  const threeMoonKASHUntransmittedEKL = await threeMoonKASHGaugeContract.methods
    .untransmittedEkl()
    .call();
  return threeMoonKASHUntransmittedEKL;
};

export const getThreeMoonPUSDUntransmittedEKL = async () => {
  const threeMoonPUSDUntransmittedEKL = await threeMoonPUSDGaugeContract.methods
    .untransmittedEkl()
    .call();
  return threeMoonPUSDUntransmittedEKL;
};

export const getThreeMoonUSDKUntransmittedEKL = async () => {
  const threeMoonUSDKUntransmittedEKL = await threeMoonUSDKGaugeContract.methods
    .untransmittedEkl()
    .call();
  return threeMoonUSDKUntransmittedEKL;
};

export const getThreeMoonUSDFUntransmittedEKL = async () => {
  const threeMoonUSDFUntransmittedEKL = await threeMoonUSDFGaugeContract.methods
    .untransmittedEkl()
    .call();
  return threeMoonUSDFUntransmittedEKL;
};

export const getTriCryptoUntransmittedEKL = async () => {
  const triCryptoUntransmittedEKL = await triCryptoGaugeContract.methods
    .untransmittedEkl()
    .call();
  return triCryptoUntransmittedEKL;
};

export const getUntransmittedEKL = async () => {
  const threeMoonUntransmittedEKL = await getThreeMoonUntransmittedEKL();
  const threeMoonBUSDUntransmittedEKL =
    await getThreeMoonBUSDUntransmittedEKL();
  const threeMoonKSDUntransmittedEKL = await getThreeMoonKSDUntransmittedEKL();
  const threeMoonKASHUntransmittedEKL =
    await getThreeMoonKASHUntransmittedEKL();
  const threeMoonPUSDUntransmittedEKL =
    await getThreeMoonPUSDUntransmittedEKL();
  const threeMoonUSDKUntransmittedEKL =
    await getThreeMoonUSDKUntransmittedEKL();

  return Math.floor(
    (Number(threeMoonUntransmittedEKL) +
      Number(threeMoonBUSDUntransmittedEKL) +
      Number(threeMoonKSDUntransmittedEKL) +
      Number(threeMoonKASHUntransmittedEKL) +
      Number(threeMoonPUSDUntransmittedEKL) +
      Number(threeMoonUSDKUntransmittedEKL)) /
      10 ** 18
  );
};

export const getVotePortion = async () => {
  const voteWeek = await voteContract.methods.currentWeek().call();
  const totalVekl = await lockContract.methods.totalVekl().call();
  const threeMoonVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonGaugeAddress)
    .call();
  const threeMoonBUSDVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonBUSDGaugeAddress)
    .call();
  const threeMoonKSDVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonKSDGaugeAddress)
    .call();
  const threeMoonKASHVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonKASHGaugeAddress)
    .call();
  const threeMoonPUSDVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonPUSDGaugeAddress)
    .call();
  const threeMoonUSDKVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonUSDKGaugeAddress)
    .call();
  const triCryptoVotePortion = await voteContract.methods
    .getPortion(voteWeek, triCryptoGaugeAddress)
    .call();

  return Number(
    ((BigInt(threeMoonVotePortion[1]) +
      BigInt(threeMoonBUSDVotePortion[1]) +
      BigInt(threeMoonKSDVotePortion[1]) +
      BigInt(threeMoonKASHVotePortion[1]) +
      BigInt(threeMoonPUSDVotePortion[1]) +
      BigInt(threeMoonUSDKVotePortion[1]) +
      BigInt(triCryptoVotePortion[1])) *
      BigInt(100)) /
      BigInt(totalVekl)
  );
};

export const getEKLPrice = async () => {
  const eklDollar = await klaySwapContract.methods
    .estimatePos(EKLTokenAddress, BigInt(10 ** 18))
    .call();

  return Number(Number(eklDollar / 10 ** 6).toPrecision(3));
};

export const getThreeMoonVotePortion = async () => {
  const voteWeek = await voteContract.methods.currentWeek().call();
  const threeMoonVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonGaugeAddress)
    .call();
  return threeMoonVotePortion;
};

export const getThreeMoonKSDVotePortion = async () => {
  const voteWeek = await voteContract.methods.currentWeek().call();
  const threeMoonKSDVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonKSDGaugeAddress)
    .call();
  return threeMoonKSDVotePortion;
};

export const getThreeMoonBUSDVotePortion = async () => {
  const voteWeek = await voteContract.methods.currentWeek().call();
  const threeMoonBUSDVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonBUSDGaugeAddress)
    .call();
  return threeMoonBUSDVotePortion;
};

export const getThreeMoonKASHVotePortion = async () => {
  const voteWeek = await voteContract.methods.currentWeek().call();
  const threeMoonKASHVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonKASHGaugeAddress)
    .call();
  return threeMoonKASHVotePortion;
};

export const getThreeMoonPUSDVotePortion = async () => {
  const voteWeek = await voteContract.methods.currentWeek().call();
  const threeMoonPUSDVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonPUSDGaugeAddress)
    .call();
  return threeMoonPUSDVotePortion;
};

export const getThreeMoonUSDKVotePortion = async () => {
  const voteWeek = await voteContract.methods.currentWeek().call();
  const threeMoonUSDKVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonUSDKGaugeAddress)
    .call();
  return threeMoonUSDKVotePortion;
};

export const getThreeMoonUSDFVotePortion = async () => {
  const voteWeek = await voteContract.methods.currentWeek().call();
  const threeMoonUSDFVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonUSDFGaugeAddress)
    .call();
  return threeMoonUSDFVotePortion;
};

export const getTriCryptoVotePortion = async () => {
  const voteWeek = await voteContract.methods.currentWeek().call();
  const triCryptoVotePortion = await voteContract.methods
    .getPortion(voteWeek, triCryptoGaugeAddress)
    .call();
  return triCryptoVotePortion;
};

export const getPortionData: () => Promise<PieChartData[]> = async () => {
  const data: PieChartData[] = [];
  const voteWeek = await voteContract.methods.currentWeek().call();
  const threeMoonVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonGaugeAddress)
    .call();
  const threeMoonBUSDVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonBUSDGaugeAddress)
    .call();
  const threeMoonKSDVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonKSDGaugeAddress)
    .call();
  const threeMoonKASHVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonKASHGaugeAddress)
    .call();
  const threeMoonPUSDVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonPUSDGaugeAddress)
    .call();
  const threeMoonUSDKVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonUSDKGaugeAddress)
    .call();
  const threeMoonUSDFVotePortion = await voteContract.methods
    .getPortion(voteWeek, threeMoonUSDFGaugeAddress)
    .call();
  const triCryptoVotePortion = await voteContract.methods
    .getPortion(voteWeek, triCryptoGaugeAddress)
    .call();
  const sum =
    BigInt(threeMoonVotePortion[1]) +
    BigInt(threeMoonBUSDVotePortion[1]) +
    BigInt(threeMoonKSDVotePortion[1]) +
    BigInt(threeMoonKASHVotePortion[1]) +
    BigInt(threeMoonPUSDVotePortion[1]) +
    BigInt(threeMoonUSDFVotePortion[1]) +
    BigInt(threeMoonUSDKVotePortion[1]);
  data.push({
    name: "3Moon",
    value:
      Number((BigInt(threeMoonVotePortion[1]) * BigInt(10000)) / sum) / 100,
  });
  data.push({
    name: "3Moon-BUSD",
    value:
      Number((BigInt(threeMoonBUSDVotePortion[1]) * BigInt(10000)) / sum) / 100,
  });
  data.push({
    name: "3Moon-KSD",
    value:
      Number((BigInt(threeMoonKSDVotePortion[1]) * BigInt(10000)) / sum) / 100,
  });
  data.push({
    name: "3Moon-KASH",
    value:
      Number((BigInt(threeMoonKASHVotePortion[1]) * BigInt(10000)) / sum) / 100,
  });
  data.push({
    name: "3Moon-PUSD",
    value:
      Number((BigInt(threeMoonPUSDVotePortion[1]) * BigInt(10000)) / sum) / 100,
  });
  data.push({
    name: "3Moon-USDK",
    value:
      Number((BigInt(threeMoonUSDKVotePortion[1]) * BigInt(10000)) / sum) / 100,
  });
  data.push({
    name: "3Moon-USDF",
    value:
      Number((BigInt(threeMoonUSDFVotePortion[1]) * BigInt(10000)) / sum) / 100,
  });
  data.push({
    name: "ekl-tri-crypto",
    value:
      Number((BigInt(triCryptoVotePortion[1]) * BigInt(10000)) / sum) / 100,
  });
  return data;
};

export const getAppliedPortionData: () => Promise<
  PieChartData[]
> = async () => {
  const data: PieChartData[] = [];
  const voteWeek = await voteContract.methods.currentWeek().call();
  const threeMoonVotePortion = await voteContract.methods
    .getPortion(voteWeek - 1, threeMoonGaugeAddress)
    .call();
  const threeMoonBUSDVotePortion = await voteContract.methods
    .getPortion(voteWeek - 1, threeMoonBUSDGaugeAddress)
    .call();
  const threeMoonKSDVotePortion = await voteContract.methods
    .getPortion(voteWeek - 1, threeMoonKSDGaugeAddress)
    .call();
  const threeMoonKASHVotePortion = await voteContract.methods
    .getPortion(voteWeek - 1, threeMoonKASHGaugeAddress)
    .call();
  const threeMoonPUSDVotePortion = await voteContract.methods
    .getPortion(voteWeek - 1, threeMoonPUSDGaugeAddress)
    .call();
  const threeMoonUSDKVotePortion = await voteContract.methods
    .getPortion(voteWeek - 1, threeMoonUSDKGaugeAddress)
    .call();
  const threeMoonUSDFVotePortion = await voteContract.methods
    .getPortion(voteWeek - 1, threeMoonUSDFGaugeAddress)
    .call();
  const triCryptoVotePortion = await voteContract.methods
    .getPortion(voteWeek - 1, triCryptoGaugeAddress)
    .call();
  const sum =
    BigInt(threeMoonVotePortion[1]) +
    BigInt(threeMoonBUSDVotePortion[1]) +
    BigInt(threeMoonKSDVotePortion[1]) +
    BigInt(threeMoonKASHVotePortion[1]) +
    BigInt(threeMoonPUSDVotePortion[1]) +
    BigInt(threeMoonUSDKVotePortion[1]) +
    BigInt(threeMoonUSDFVotePortion[1]) +
    BigInt(triCryptoVotePortion[1]);
  data.push({
    name: "3Moon",
    value:
      Number((BigInt(threeMoonVotePortion[1]) * BigInt(10000)) / sum) / 100,
  });
  data.push({
    name: "3Moon-BUSD",
    value:
      Number((BigInt(threeMoonBUSDVotePortion[1]) * BigInt(10000)) / sum) / 100,
  });
  data.push({
    name: "3Moon-KSD",
    value:
      Number((BigInt(threeMoonKSDVotePortion[1]) * BigInt(10000)) / sum) / 100,
  });
  data.push({
    name: "3Moon-KASH",
    value:
      Number((BigInt(threeMoonKASHVotePortion[1]) * BigInt(10000)) / sum) / 100,
  });
  data.push({
    name: "3Moon-PUSD",
    value:
      Number((BigInt(threeMoonPUSDVotePortion[1]) * BigInt(10000)) / sum) / 100,
  });
  data.push({
    name: "3Moon-USDK",
    value:
      Number((BigInt(threeMoonUSDKVotePortion[1]) * BigInt(10000)) / sum) / 100,
  });
  data.push({
    name: "3Moon-USDF",
    value:
      Number((BigInt(threeMoonUSDFVotePortion[1]) * BigInt(10000)) / sum) / 100,
  });
  data.push({
    name: "tri-crypto",
    value:
      Number((BigInt(triCryptoVotePortion[1]) * BigInt(10000)) / sum) / 100,
  });
  return data;
};

export const getUserVekl = async (address: string) => {
  const vEKL = await lockContract.methods.getUserVekl(address).call();
  return vEKL;
};

export const getAllLockedUser = async (
  setBlockNumber: (block: number) => void
) => {
  let block = START_BLOCK;
  const userMap: any = {};
  let lockedUser: any[] = [];
  const uniqueUser: any[] = [];
  for (let i = 0; i < 100; i++) {
    const { done, events } = await getPastLockEvents(
      block,
      block + EVENTS_PER_QUERY
    );
    lockedUser = [
      ...lockedUser,
      ...events.map((eachEvent) => eachEvent.returnValues!.user),
    ];
    await new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(0);
      }, 1300);
    });
    if (done) {
      break;
    } else {
      block += EVENTS_PER_QUERY;
      setBlockNumber(block);
    }
  }

  lockedUser.forEach((user) => {
    if (!userMap[user]) {
      userMap[user] = true;
      uniqueUser.push(user);
    }
  });
  return uniqueUser;
};

export const getPastLockEvents = async (fromBlock: number, toBlock: number) => {
  let events;
  let done;
  try {
    events = await lockContract.getPastEvents("Lock", { fromBlock, toBlock });
  } catch {
    done = true;
    events = await lockContract.getPastEvents("Lock", {
      fromBlock,
      toBlock: "latest",
    });
  }
  return {
    done,
    events,
  };
};

const getSwapTVL = async () => {
  const basePrice = await threeMoonSwapContract.methods
    .getVirtualPrice()
    .call();
  const rawBaseBalance = await threeMoonSwapContract.methods
    .getTokenBalances()
    .call();
  const baseBalance =
    BigInt(rawBaseBalance[0]) +
    BigInt(rawBaseBalance[1] * 10 ** 12) +
    BigInt(rawBaseBalance[2] * 10 ** 12);

  // const fMoonSwaps = Object.entries(SWAP_ADDRESS).slice(1);
  const fourMoonTVL = allSwapContract
    .map(async (address, index) => {
      if (index === 0) {
        return BigInt(0);
      }
      const swap = new caver.klay.Contract(EklipseLp4moonABI as any, address);
      const balances = await swap.methods.getTokenBalances().call();
      return BigInt((balances[0] * basePrice) / 10 ** 18) + BigInt(balances[1]);
    })
    .reduce(async (a, b) => (await a) + (await b));
  const cryptoPoolTVL = allCryptoPoolAddress
    .map(async (address, index) => {
      const swap = new caver.klay.Contract(cryptoSwapAbi as any, address);
      let balances: any[] = await swap.methods.getTokenBalances().call();
      const prices = await swap.methods.getTokenPrice().call();
      balances = balances.map((bal, index) => {
        if (index !== 1) {
          return BigInt(Math.floor((bal * prices[index]) / 10 ** 6));
        } else {
          return BigInt(Math.floor((bal * prices[index]) / 10 ** 18));
        }
      });

      return balances.reduce((a, b) => a + b);
    })
    .reduce(async (a, b) => (await a) + (await b));
  return (
    BigInt(baseBalance + (await fourMoonTVL) + (await cryptoPoolTVL)) /
    BigInt(10 ** 18)
  );
};

const getEKLFarmTVL = async () => {
  const usdtLpBalance = await eklUsdtLpTokenContract.methods
    .balanceOf(lpFarmAddress)
    .call();
  const usdtLpPrice = await getEklUsdtLpPrice();
  const klayLpBalance = await eklKlayLpTokenContract.methods
    .balanceOf(lpFarmAddress)
    .call();

  const klayLpPrice = await getEklKlayLpPrice();

  return (
    (BigInt(Math.floor(usdtLpBalance * usdtLpPrice)) +
      BigInt(Math.floor(klayLpBalance * klayLpPrice))) /
    BigInt(10 ** 18)
  );
};

const getEklUsdtLpPrice = async () => {
  const usdtKslpBalance = await eklUsdtLpTokenContract.methods
    .getCurrentPool()
    .call();
  const usdtKslpTVL = usdtKslpBalance[1] * 2;
  const usdtKslpTotalSupply = await eklUsdtLpTokenContract.methods
    .totalSupply()
    .call();
  return usdtKslpTVL / usdtKslpTotalSupply;
};

const getEklKlayLpPrice = async () => {
  const eklPrice = await getEKLPrice();
  const klayKslpBalance = await eklKlayLpTokenContract.methods
    .getCurrentPool()
    .call();
  const klayKslpTVL = klayKslpBalance[1] * 2;
  const klayKslpTotalSupply = await eklKlayLpTokenContract.methods
    .totalSupply()
    .call();
  return (klayKslpTVL / klayKslpTotalSupply) * eklPrice;
};

const getGaugeTVL = async () => {
  return allLpTokenAddress
    .map(async (lptoken, index) => {
      const tokenBalance = await new caver.klay.Contract(
        erc20ABI as any,
        lptoken
      ).methods
        .balanceOf(allGaugeContractAddress[index])
        .call();
      const virtualPrice = await new caver.klay.Contract(
        EklipseLp4moonABI as any,
        allSwapContract[index]
      ).methods
        .getVirtualPrice()
        .call();

      return (
        BigInt(Math.floor(tokenBalance * virtualPrice)) /
        BigInt(10 ** 18) /
        BigInt(10 ** 18)
      );
    })
    .reduce(async (a, b) => (await a) + (await b));
};

const getTotalLockEKLValue = async () => {
  const balance = await eklTokenContract.methods
    .balanceOf(lockContractAddress)
    .call();
  const price = await getEKLPrice();
  return BigInt(Math.floor(balance * price)) / BigInt(10 ** 18);
};

export const getTVL = async () => {
  const swapTVL = await getSwapTVL();
  const eklFarmTVL = await getEKLFarmTVL();
  const gaugeTVL = await getGaugeTVL();
  const lockTVL = await getTotalLockEKLValue();
  return Number(swapTVL + eklFarmTVL + gaugeTVL + lockTVL);
};

export const get24Volume = async () => {
  const col = collection(db, "tv");
  const snapshot = await getDocs(col);
  const tvlObject = snapshot.docs.map((doc) => doc.data())[0];
  delete tvlObject["total"];
  const value = Object.values(tvlObject).reduce((a, b) => {
    return a + b.volume + b.depositVolume + b.withdrawVolume;
  }, 0);

  return Math.floor(value);
};
