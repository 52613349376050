import { initializeApp } from "firebase/app";
import { firebaseEnvironment } from "../const/index";
import { getFirestore } from "firebase/firestore/lite";
export const thousandSeparator = (number: number) => {
  let integer = String(number).split(".")[0];
  const float = String(number).split(".")[1];
  for (let i = 0; i < integer.length; i++) {
    if (i % 4 == 3) {
      integer = [
        integer.slice(0, integer.length - 1 - i + 1),
        ",",
        integer.slice(integer.length - 1 - i + 1),
      ].join("");
    }
  }
  return float ? integer + "." + float : integer;
};

export const getHashedAddress = (address: string) => {
  return address
    .split("")
    .map((v, i) => {
      if (6 <= i && i < address.length - 4) {
        return "_";
      } else {
        return v;
      }
    })
    .join("");
};

const app = initializeApp(firebaseEnvironment);
export const db = getFirestore(app);
