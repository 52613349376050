import { useEffect, useState } from "react";
import { GaugeInfoService, gaugeType } from "../../service/GaugeInfoService";
import { thousandSeparator } from "../../utils/util";
import "./GaugeInfo.scss";

interface Props {
  gaugeType: gaugeType;
}

const GaugeInfo = ({ gaugeType }: Props) => {
  const [tokens, setTokens] = useState<string[]>([]);
  const [gaugeName, setGaugeName] = useState("");
  const [untransmittedEKL, setUntransmittedEKL] = useState("");
  const [currentVotedAmount, setCurrentVotedAmount] = useState("");
  const init = async () => {
    const gaugeInfoService = new GaugeInfoService(gaugeType);
    const untransmittedEKL = await gaugeInfoService.getUntransmittedEKL();
    const voteResult = await gaugeInfoService.getVotingResult();
    setUntransmittedEKL(
      thousandSeparator(Number(BigInt(untransmittedEKL) / BigInt(10 ** 18)))
    );
    setGaugeName(gaugeInfoService.getGaugeName());
    setCurrentVotedAmount(
      thousandSeparator(Number(BigInt(voteResult[1]) / BigInt(10 ** 18))) +
        ` (${BigInt(voteResult[1] * 100) / BigInt(voteResult[0])}%)`
    );
    setTokens(gaugeInfoService.getTokenList());
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <div className="gauge-info">
      <div className="token-container">
        {tokens.map((token) => {
          return <div className={"token " + token} key={token}></div>;
        })}
        <span className="gauge-name">{gaugeName}</span>
      </div>
      <div className="info-container">
        <div className="info-row">
          <span className="info-label untransmiteed-ekl">
            Untransmitted EKL
          </span>
          <span className="amount">{untransmittedEKL}</span>
        </div>
        <div className="info-row">
          <span className="info-label voted-amount">Voted amount(vEKL)</span>
          <span className="amount">{currentVotedAmount}</span>
        </div>
      </div>
    </div>
  );
};

export default GaugeInfo;
