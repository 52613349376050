import {
  getPopkornFee,
  getThreeMoonBUSDFee,
  getThreeMoonFee,
  getThreeMoonKASHFee,
  getThreeMoonKSDFee,
  getThreeMoonPUSDFee,
  getThreeMoonUSDFFee,
  getThreeMoonUSDKFee,
  getTriCryptoFee,
} from "../utils/contractCall";

export enum swapType {
  ThreeMoon = "ThreeMoon",
  BUSD = "BUSD",
  KSD = "KSD",
  KASH = "KASH",
  PUSD = "PUSD",
  USDK = "USDK",
  USDF = "USDF",
  TRICRYPTO = "TRICRYPTO",
  PUSD_KORN = "PUSD_KORN",
}

export class SwapInfoService {
  swapType?: swapType;
  constructor(swapType: swapType) {
    this.swapType = swapType;
  }

  getSwapName() {
    if (this.swapType === swapType.ThreeMoon) {
      return "3Moon";
    } else if (this.swapType === swapType.BUSD) {
      return "3Moon-BUSD";
    } else if (this.swapType === swapType.KSD) {
      return "3Moon-KSD";
    } else if (this.swapType === swapType.KASH) {
      return "3Moon-KASH";
    } else if (this.swapType === swapType.PUSD) {
      return "3Moon-PUSD";
    } else if (this.swapType === swapType.USDK) {
      return "3Moon-USDK";
    } else if (this.swapType === swapType.USDF) {
      return "3Moon-USDF";
    } else if (this.swapType === swapType.TRICRYPTO) {
      return "Ekl tri-crypto";
    } else if (this.swapType === swapType.PUSD_KORN) {
      return "PUSD-KORN";
    }
    return "";
  }

  async getSwapFee() {
    if (this.swapType === swapType.ThreeMoon) {
      const fee = await getThreeMoonFee();
      return this.getTokenList().map((token, index) => {
        if (index > 0) {
          return { token, fee: BigInt(fee[index]) / BigInt(10 ** 6) };
        }
        return { token, fee: BigInt(fee[index]) / BigInt(10 ** 18) };
      });
    } else if (this.swapType === swapType.BUSD) {
      const fee = await getThreeMoonBUSDFee();
      return this.getTokenList().map((token, index) => {
        return { token, fee: BigInt(fee[index]) / BigInt(10 ** 18) };
      });
    } else if (this.swapType === swapType.KSD) {
      const fee = await getThreeMoonKSDFee();
      return this.getTokenList().map((token, index) => {
        return { token, fee: BigInt(fee[index]) / BigInt(10 ** 18) };
      });
    } else if (this.swapType === swapType.KASH) {
      const fee = await getThreeMoonKASHFee();
      return this.getTokenList().map((token, index) => {
        return { token, fee: BigInt(fee[index]) / BigInt(10 ** 18) };
      });
    } else if (this.swapType === swapType.PUSD) {
      const fee = await getThreeMoonPUSDFee();
      return this.getTokenList().map((token, index) => {
        return { token, fee: BigInt(fee[index]) / BigInt(10 ** 18) };
      });
    } else if (this.swapType === swapType.USDK) {
      const fee = await getThreeMoonUSDKFee();
      return this.getTokenList().map((token, index) => {
        return { token, fee: BigInt(fee[index]) / BigInt(10 ** 18) };
      });
    } else if (this.swapType === swapType.USDF) {
      const fee = await getThreeMoonUSDFFee();
      return this.getTokenList().map((token, index) => {
        return { token, fee: BigInt(fee[index]) / BigInt(10 ** 18) };
      });
    } else if (this.swapType === swapType.TRICRYPTO) {
      const fee = await getTriCryptoFee();
      return this.getTokenList().map((token, index) => {
        return { token, fee: BigInt(fee[index]) / BigInt(10 ** 6) };
      });
    } else if (this.swapType === swapType.PUSD_KORN) {
      const fee = await getPopkornFee();
      return this.getTokenList().map((token, index) => {
        return { token, fee: BigInt(fee[index]) / BigInt(10 ** 18) };
      });
    }
    return [];
  }

  getTokenList() {
    if (this.swapType === swapType.ThreeMoon) {
      return ["kDAI", "kUSDT", "kUSDC"];
    } else if (this.swapType === swapType.BUSD) {
      return ["3Moon-LP", "kBUSD"];
    } else if (this.swapType === swapType.KSD) {
      return ["3Moon-LP", "KSD"];
    } else if (this.swapType === swapType.KASH) {
      return ["3Moon-LP", "KASH"];
    } else if (this.swapType === swapType.PUSD) {
      return ["3Moon-LP", "PUSD"];
    } else if (this.swapType === swapType.USDK) {
      return ["3Moon-LP", "USDK"];
    } else if (this.swapType === swapType.USDF) {
      return ["3Moon-LP", "USDF"];
    } else if (this.swapType === swapType.TRICRYPTO) {
      return ["kUSDT", "KLAY", "XRP"];
    } else if (this.swapType === swapType.PUSD_KORN) {
      return ["PUSD", "KORN"];
    }
    return [];
  }
}
